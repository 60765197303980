import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class V2ClientModel extends Model {
  @attr('array') availableCremAuthHeaders;
  
  @attr('boolean', { defaultValue: false }) canHideDeathNotice;
  @attr('boolean', { defaultValue: false }) canLockPostingPeriods;
  @attr('boolean', { defaultValue: false }) canMarkBadDebt;
  @attr('boolean', { defaultValue: false }) canSeparateTaxCalculations;
  @attr('boolean', { defaultValue: false }) canSetKaseStatusFilter;
  @attr('boolean', { defaultValue: false }) canUseSingleKaseTag;
  @attr('boolean', { defaultValue: false }) canViewCemsites;
  @attr('boolean', { defaultValue: false }) canViewChainOfCustody;
  @attr('boolean', { defaultValue: false }) canViewCustomFieldsConfig;
  @attr('boolean', { defaultValue: false }) canViewCustomSelectionSorting;
  @attr('boolean', { defaultValue: false }) canViewEnhancedPackages;
  @attr('boolean', { defaultValue: false }) canViewFinancialConfigAccounts;
  @attr('boolean', { defaultValue: false }) canViewFinancialConfigAdjustments;
  @attr('boolean', { defaultValue: false }) canViewFinancialConfigBankAccounts;
  @attr('boolean', { defaultValue: false }) canViewFinancialConfigDiscounts;
  @attr('boolean', { defaultValue: false }) canViewFinancialConfigInterests;
  @attr('boolean', { defaultValue: false }) canViewFinancialConfigPayments;
  @attr('boolean', { defaultValue: false }) canViewFinancialConfigPostingPeriods;
  @attr('boolean', { defaultValue: false }) canViewFinancialConfigSpecialTaxes;
  @attr('boolean', { defaultValue: false }) canViewFinancialConfigSync;
  @attr('boolean', { defaultValue: false }) canViewFinancialConfigTaxItems;
  @attr('boolean', { defaultValue: false }) canViewGeneralPayments;
  @attr('boolean', { defaultValue: false }) canViewInterests;
  @attr('boolean', { defaultValue: false }) canViewKaseLinks;
  @attr('boolean', { defaultValue: false }) canViewKaseTags;
  @attr('boolean', { defaultValue: false }) canViewKaseTagsAdmin;
  @attr('boolean', { defaultValue: false }) canViewLocationSpecificUsers;
  @attr('boolean', { defaultValue: false }) canViewMimsStationary;
  @attr('boolean', { defaultValue: false }) canViewOnlinePlanner;
  @attr('boolean', { defaultValue: false }) canViewPackageSlotAllowances;
  @attr('boolean', { defaultValue: false }) canViewPackageSlotFlexibleItems;
  @attr('boolean', { defaultValue: false }) canViewPoems;
  @attr('boolean', { defaultValue: false }) canViewPullFromBeaconfpInventory;
  @attr('boolean', { defaultValue: false }) canViewReceiptPaymentNotes;
  @attr('boolean', { defaultValue: false }) canViewSmsPaymentLink;
  @attr('boolean', { defaultValue: false }) canViewStatementCategories;
  @attr('boolean', { defaultValue: false }) canViewVehicles;
  @attr('boolean', { defaultValue: false }) canViewVendors;
  @attr('boolean', { defaultValue: false }) hasDiscretionaryItems;
  @attr('boolean', { defaultValue: false }) setContractDateToPostedDateIfNoEvents;
  @attr('boolean', { defaultValue: true }) canViewAuditLogs;
  @attr('boolean', { defaultValue: true }) canViewCalendar;
  @attr('boolean', { defaultValue: true }) canViewCaseDispositionIcon;
  @attr('boolean', { defaultValue: true }) canViewCaseFinancial;
  @attr('boolean', { defaultValue: true }) canViewCogsCustomProducts;
  @attr('boolean', { defaultValue: true }) canViewContacts;
  @attr('boolean', { defaultValue: true }) canViewCremationAuthorizationSection;
  @attr('boolean', { defaultValue: true }) canViewCustomCaseFields;
  @attr('boolean', { defaultValue: true }) canViewDashChainCustody;
  @attr('boolean', { defaultValue: true }) canViewDashEvents;
  @attr('boolean', { defaultValue: true }) canViewDashKases;
  @attr('boolean', { defaultValue: true }) canViewDashNav;
  @attr('boolean', { defaultValue: true }) canViewDashPastDue;
  @attr('boolean', { defaultValue: true }) canViewDashTasks;
  @attr('boolean', { defaultValue: true }) canViewData;
  @attr('boolean', { defaultValue: true }) canViewDisposition;
  @attr('boolean', { defaultValue: true }) canViewDocumentsSection;
  @attr('boolean', { defaultValue: true }) canViewEvents;
  @attr('boolean', { defaultValue: true }) canViewFamilyFriends;
  @attr('boolean', { defaultValue: true }) canViewFinancialConfigurations;
  @attr('boolean', { defaultValue: true }) canViewFinancialDetail;
  @attr('boolean', { defaultValue: true }) canViewFormInTfe;
  @attr('boolean', { defaultValue: true }) canViewForms;
  @attr('boolean', { defaultValue: true }) canViewFormsAdmin;
  @attr('boolean', { defaultValue: true }) canViewGeneralPriceLists;
  @attr('boolean', { defaultValue: true }) canViewGenerateSfgs;
  @attr('boolean', { defaultValue: true }) canViewGroups;
  @attr('boolean', { defaultValue: true }) canViewGrowthEngine;
  @attr('boolean', { defaultValue: true }) canViewInfo;
  @attr('boolean', { defaultValue: true }) canViewInquiriesGlobal;
  @attr('boolean', { defaultValue: true }) canViewInquiriesSection;
  @attr('boolean', { defaultValue: true }) canViewIsCovid19Case;
  @attr('boolean', { defaultValue: true }) canViewKases;
  @attr('boolean', { defaultValue: true }) canViewLocations;
  @attr('boolean', { defaultValue: true }) canViewObituarySection;
  @attr('boolean', { defaultValue: true }) canViewOrganizations;
  @attr('boolean', { defaultValue: true }) canViewPlaces;
  @attr('boolean', { defaultValue: true }) canViewPreneedInfoMessage;
  @attr('boolean', { defaultValue: true }) canViewPreneedSection;
  @attr('boolean', { defaultValue: true }) canViewPreparationsSection;
  @attr('boolean', { defaultValue: true }) canViewPrintablesSection;
  @attr('boolean', { defaultValue: true }) canViewPullFromInventory;
  @attr('boolean', { defaultValue: true }) canViewReceiptLocation;
  @attr('boolean', { defaultValue: true }) canViewReferralInfoSection;
  @attr('boolean', { defaultValue: true }) canViewReports;
  @attr('boolean', { defaultValue: true }) canViewServiceInfoSection;
  @attr('boolean', { defaultValue: true }) canViewSoaLocation;
  @attr('boolean', { defaultValue: true }) canViewTransportationSection;
  @attr('boolean', { defaultValue: true }) canViewTributePay;
  @attr('boolean', { defaultValue: true }) canViewTributeVideoAdmin;
  @attr('boolean', { defaultValue: true }) canViewUsers;
  @attr('boolean', { defaultValue: true }) canViewVeteranInfoSection;
  @attr('boolean', { defaultValue: true }) canViewWhiteboard;
  @attr('boolean', { defaultValue: true }) defaults_due_date;
  @attr('boolean', { defaultValue: true }) hasCustomProducts;
  @attr('boolean') autoKaseNumbering;
  @attr('boolean') blockEsignForUnpostedContracts;
  @attr('boolean') canAssignInsuranceToPayments;
  @attr('boolean') canUseCustomSfgs;
  @attr('boolean') canUseTfeCustomUpload;
  @attr('boolean') canUseTfeEsign;
  @attr('boolean') canViewAccounting;
  @attr('boolean') canViewAdditionalProductInfo;
  @attr('boolean') canViewAdjustmentGains;
  @attr('boolean') canViewBatchPayments;
  @attr('boolean') canViewBestWishes;
  @attr('boolean') canViewCiclops;
  @attr('boolean') canViewContactManager;
  @attr('boolean') canViewDigitalSignatures;
  @attr('boolean') canViewDiscounts;
  @attr('boolean') canViewDoNotExport;
  @attr('boolean') canViewEmployeeArrivalTime;
  @attr('boolean') canViewEsignature;
  @attr('boolean') canViewFamilyArrivalTime;
  @attr('boolean') canViewFamilyPortal;
  @attr('boolean') canViewFuneralDecisions;
  @attr('boolean') canViewInventory;
  @attr('boolean') canViewLineItemAdjustments;
  @attr('boolean') canViewMiscSales;
  @attr('boolean') canViewObitGenerator;
  @attr('boolean') canViewOhioEdrs;
  @attr('boolean') canViewPriceListDates;
  @attr('boolean') canViewReceiptBalance;
  @attr('boolean') canViewReceiptSignature;
  @attr('boolean') canViewReceiptThanks;
  @attr('boolean') canViewSpecialTaxes;
  @attr('boolean') canViewTasks;
  @attr('boolean') canViewTasksAdmin;
  @attr('boolean') canViewTfeEsignatureDashboard;
  @attr('boolean') canViewTributePrint;
  @attr('boolean') eventStaffMemberAssigneeResponse;
  @attr('boolean') eventStaffMemberInvites;
  @attr('boolean') eventStaffMemberLeadResponse;
  @attr('boolean') forceAutoKaseNumbering;
  @attr('boolean') hasOhioEdrs;
  @attr('boolean') hasTributePayAccount;
  @attr('boolean') includePostingDateInFinancialDetailsExport;
  @attr('boolean') isEsignFieldTest;
  @attr('boolean') miscSaleUseGenericSoa;
  @attr('boolean') onlyFuneralDirectorsCanBeLeadStaffMembers;
  @attr('boolean') receiptShowPaidInFull;
  @attr('boolean') relabelContractToInvoice;
  @attr('boolean') requireReasonForUnposting;
  @attr('boolean') sendFamilyPortalEmailInvite;
  @attr('boolean') shouldFlattenForms;
  @attr('boolean') soaHideDiscount;
  @attr('boolean') soaShowContractDate;
  @attr('boolean') soaShowDeathDate;
  @attr('boolean') soaShowKaseInfo;
  @attr('boolean') soaShowPaidInFull;
  @attr('boolean') soaShowProductNotes;
  @attr('boolean') soaUseCustomMargins;
  @attr('boolean') usePrototypeTypeahead;

  @attr('moment-datetime') expiresAt;

  @attr('number') humanKases;
  @attr('number') petKases;
  @attr('number') soaCustomFontSize;
  @attr('number') totalDiscountedKases;

  @attr('string', { defaultValue: 'Active Customer' }) clientType;
  @attr('string', { defaultValue: 'tms' }) logoBranding;
  @attr('string', { defaultValue: 'Eastern Time (US & Canada)' }) timeZone;
  @attr('string') answeringService;
  @attr('string') callVolume;
  @attr('string') cremAuthHeaderService;
  @attr('string') name;
  @attr('string', { defaultValue: 'automatic' }) newUserOrLocationDefaultBehavior;
  @attr('string', { defaultValue: 'US' }) operatingCountry;
  @attr('string') plan;
  @attr('string') printCaseType;
  @attr('string') soaMarginBottom;
  @attr('string') soaMarginLeft;
  @attr('string') soaMarginRight;
  @attr('string') soaMarginTop;
  @attr('string') soaType;
  @attr('string') websiteService;

  // @belongsTo('blankContract', { async: true }) blankContract;
  // @belongsTo('reportConfig', { async: true }) reportConfig;
  @belongsTo('v2/selectionValidationsConfig', { async: true }) selectionValidationsConfig;

  @hasMany('v2/location') locations;
  // @hasMany('organization') organizations;
  @hasMany('v2/contractTemplate', { async: true }) contractTemplates;
  @hasMany('v2/tag') tags;
}
