import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class KasesManageDocumentsAlbumsManageRoute extends Route {
  @service session;

  model() {
    const kase = this.modelFor('authenticated.kases.manage');

    return this.store.query('v2/album', {
      include: 'photos,cover-photo',
      kase_id: kase.get('id'),
      per_page: 5000,
      visibility: 'all'
    });
  }

  async setupController(controller, model) {
    super.setupController(...arguments);
    const kase = this.modelFor('authenticated.kases.manage');
    const v2Kase = this.store.findRecord('v2/kase', kase.get('id'));
    const v2User = this.store.findRecord(
      'v2/user',
      this.session.currentUser.id
    );
    const tributeWebsiteKaseIntegration = (await this.store.query('v2/kaseIntegration', {
      kase_id: model.get('id'),
      tribute_website: true
    }))?.firstObject;

    controller.set('kase', v2Kase);
    controller.set('user', v2User);
    controller.set('albums', model);
    controller.set('tributeWebsiteId', tributeWebsiteKaseIntegration?.get('remoteId'));    
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
