import Route from '@ember/routing/route'
import { inject as service } from '@ember/service';

export default class AdminUsersEditLocationAccessRoute extends Route {
  @service router;
  @service session;
  @service userConfig;

  model() {
    return this.modelFor('authenticated.admin.users.edit');
  }

  setupController(controller, model) {
    super.setupController(...arguments)

    controller.set('user', model);
  }
}
