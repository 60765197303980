import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { buildValidations, validator } from 'ember-cp-validations';
import { inject as service } from '@ember/service';

const Validations = buildValidations({
    bankName: validator('presence', {
        presence: true,
        message: 'This field can’t be blank'
    }),
    bankAccountName: validator('presence', {
        presence: true,
        message: 'This field can’t be blank'
    }),
    accountNumberLastFour: validator('presence', {
        presence: true,
        message: 'This field can’t be blank'
    }),
    routingNumberLastFour: validator('presence', {
        presence: true,
        message: 'This field can’t be blank'
    }),
    generalLedgerAccountId: validator('presence', {
        presence: true,
        message: 'This field can’t be blank'
    })
});

export default class AuthenticatedAdminFinancialConfigurationsEditBankAccountsEditController extends Controller.extend(Validations) {
    @service flashes;
    @service intl;

    @tracked bankName;
    @tracked bankAccountName;
    @tracked accountNumberLastFour;
    @tracked routingNumberLastFour;
    @tracked generalLedgerAccountId;

    @tracked showValidations = false;

    get paddedAccountNumberLastFour() {
        if(!this.accountNumberLastFour) return '';
        return `•••••••${this.accountNumberLastFour}`;
    }

    get paddedRoutingNumberLastFour() {
        if(!this.routingNumberLastFour) return '';
        return `•••••${this.routingNumberLastFour}`;
    }

    @action
    updateAccountNumberLastFour(value) {
        set(this, 'accountNumberLastFour', value);
    }

    @action
    updateRoutingNumberLastFour(value) {
        set(this, 'routingNumberLastFour', value);
    }

    @action
    changeGLAccount(GLAccountId) {
        set(this, 'generalLedgerAccountId', GLAccountId)
    }

    @action
    saveBankAccount(){
        this.validate().then(({ validations }) => {
            if (validations.get('isValid')) {
                this.showValidations = false;
            } else {
                this.showValidations = true;
            }
        });
    }
    
    @action
    removeStaff(item) {
        this.model.staffMembers.removeObject(item);
        this.flashes.addSuccess(this.intl.t('authenticated.admin.financial-configurations.edit.bank-accounts.edit.sections.staffMemberPermissions.messages.removeStaff.success'));
    }
}
