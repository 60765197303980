import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class KasesEditSettingsRoute extends Route {
  @service store;
  @service router;
  layout = 'custom-aside';
  tributeWebsiteKaseIntegration = null;

  async model() {
    const kase = this.modelFor('authenticated.kases.manage');
    this.tributeWebsiteKaseIntegration = (await this.store.query('v2/kaseIntegration', {
      kase_id: kase.get('id'),
      tribute_website: true
    }))?.firstObject;
    return kase;
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.set('kase', model);
    controller.set('tributeWebsiteKaseIntegration', this.tributeWebsiteKaseIntegration);
    controller.set('tributeWebsiteId', this.tributeWebsiteKaseIntegration?.get('remoteId'));
  }
}
