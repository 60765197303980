import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedKasesManageIntegrationsIndexRoute extends Route {
  @service api;
  @service flashes;
  @service router;
  @service session;

  layout = 'custom-aside';

  model() {
    return this.modelFor('authenticated.kases.manage.integrations');
  }

  async setupController(controller, model) {
    super.setupController(...arguments);

    const tributeWebsiteKaseIntegration = (await this.store.query('v2/kaseIntegration', {
      kase_id: model.get('id'),
      tribute_website: true
    }))?.firstObject;
    const tributeWebsiteKaseExported = Boolean(tributeWebsiteKaseIntegration?.get('remoteId'));
    controller.set('tributeWebsiteKaseExported', tributeWebsiteKaseExported);

    if (this.session.currentClient.can_view_tribute_website && tributeWebsiteKaseExported) {
      this.api.json.get(`tribute_website/kases/${model.id}/external_case`).then((response) => {
        controller.set('tributeWebsiteKase', response.ok ? response.parsedJson : undefined);
      }).catch(() => {
        this.flashes.addError('There was a problem getting the case from tribute-website.');
      })
    }

    controller.set('tributeVideoExport', this.store.queryRecord('integrationExport', {
      kase_id: model.id,
      type: 'tribute_video',
      most_recent_export: true
    }));

    controller.set('integrationServices', this.store.query('integrationService', {
      kase_id: model.id,
      not_category: ['accounting', 'answering_service'],
      per_page: 100,
      status: 1 // successful
    }));
  }
}
