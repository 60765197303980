import Model, {attr, hasMany} from '@ember-data/model';

export default class TributePayV1BankAccountModel extends Model{
    @attr('string') accountNumberLastFour;
    @attr('string') bankAccountName;
    @attr('string') bankName;
    @attr('string') generalLedgerAccountId;
    @attr('string') generalLedgerAccountName;
    @attr('string') routingNumberLastFour;
    
    @hasMany('user') staffMembers;
}
