import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
export default class AuthenticatedAdminFinancialConfigurationsEditBankAccountsEditRoute extends Route { 
    @service router;
    @service session;
    async model(params){
        const coa = this.modelFor('authenticated.admin.financial-configurations.edit');
        const accounts = await this.store.query('v2/account', {
            chart_of_accounts_id: coa.get('id'),
            archived: false,
            per_page: null,
            ...params,
        });
        const bankAccount = await this.store.findRecord('tribute-pay/v1/bank-account', params.id);
        return { accounts, bankAccount };
    }
    beforeModel() {
        if (
          !this.session.currentUser.isSuperuser &&
          !this.session.currentClient.can_view_financial_config_bank_accounts
        ) {
          this.router.transitionTo('authenticated.dashboard');
        }
    }
    setupController(controller, model) {
      super.setupController(controller, model);

      controller.setProperties({
        bankName: model.bankAccount.get('bankName'),
        bankAccountName: model.bankAccount.get('bankAccountName'),
        accountNumberLastFour: model.bankAccount.get('accountNumberLastFour'),
        routingNumberLastFour: model.bankAccount.get('routingNumberLastFour'),
        generalLedgerAccountId: model.bankAccount.get('generalLedgerAccountId')
      });
    }
    resetController(controller, isExiting) {
      if (isExiting) {
          controller.setProperties({
            bankName: null,
            bankAccountName: null,
            accountNumberLastFour: null,
            routingNumberLastFour: null,
            generalLedgerAccountId: null
          });
        }
    }
}
